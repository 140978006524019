<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public">
      <v-app id="inspire" class="app">
        <app-drawer class="app--drawer"></app-drawer>
        <app-toolbar class="app--toolbar"></app-toolbar>
        <v-content>
          <!-- Page Header -->
          <page-header v-if="$route.meta.breadcrumb"></page-header>
          <div class="page-wrapper">
            <router-view></router-view>
          </div>
          <!-- App Footer -->
          <v-footer height="auto" class="white pa-3 app--footer">
            <span class="caption"
              >Varejão da Sucata &copy; {{ new Date().getFullYear() }}</span
            >
            <v-spacer></v-spacer>
            <span class="caption mr-1"> Departamento de Tecnologia da Informação </span>
            <v-icon color="green" small>android</v-icon>
          </v-footer>
        </v-content>
        <!-- Go to top -->
        <app-fab></app-fab>
        <!-- theme setting -->
        <!-- <v-btn small fab dark falt fixed top="top" right="right" class="setting-fab" color="red" @click="openThemeSettings">
          <v-icon>settings</v-icon>
        </v-btn> -->
        <v-navigation-drawer
          class="setting-drawer"
          temporary
          right
          v-model="rightDrawer"
          hide-overlay
          fixed
        >
          <theme-settings></theme-settings>
        </v-navigation-drawer>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>

    <usuario></usuario>
  </div>
</template>
<script>
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";
import AppFab from "@/components/AppFab";
import PageHeader from "@/components/PageHeader";
import menu from "@/api/menu";
import ThemeSettings from "@/components/ThemeSettings";
import AppEvents from "./event";
import Usuario from "@/components/usuario/Perfil.vue";
export default {
  components: {
    Usuario,
    AppDrawer,
    AppToolbar,
    AppFab,
    PageHeader,
    ThemeSettings,
  },
  data: () => ({
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  }),

  computed: {},

  created() {
    AppEvents.forEach((item) => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;
    this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
    // console.log(this.getAllUrlParams(window.location.hash));
    let params = this.getAllUrlParams(window.location.hash);
    // console.log(params);
    if (params.token) {
      this.loginByToken(params.token, params.route);
    } else {
      //Validando Login
      if (!this.$session.exists()) {
        this.logout();
      } else {
        this.user = this.$session.get("user");
        this.$store.state.user = this.$session.get("user");
        // this.user = this.$store.state.user
        this.$store.commit("setUser", this.user);
        this.$http.defaults.headers.common["Authorization"] = "Bearer " + this.user.token;
      }
    }
  },
  methods: {
    getAllUrlParams(url) {
      // get query string from url (optional) or window
      var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

      // we'll store the parameters here
      var obj = {};

      // if query string exists
      if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split("#")[0];

        // split our query string into its component parts
        var arr = queryString.split("&");

        for (var i = 0; i < arr.length; i++) {
          // separate the keys and the values
          var a = arr[i].split("=");

          // set parameter name and value (use 'true' if empty)
          var paramName = a[0];
          var paramValue = typeof a[1] === "undefined" ? true : a[1];

          // (optional) keep case consistent
          //paramName = paramName.toLowerCase();
          //if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

          // if the paramName ends with square brackets, e.g. colors[] or colors[2]
          if (paramName.match(/\[(\d+)?\]$/)) {
            // create key if it doesn't exist
            var key = paramName.replace(/\[(\d+)?\]/, "");
            if (!obj[key]) obj[key] = [];

            // if it's an indexed array e.g. colors[2]
            if (paramName.match(/\[\d+\]$/)) {
              // get the index value and add the entry at the appropriate position
              var index = /\[(\d+)\]/.exec(paramName)[1];
              obj[key][index] = paramValue;
            } else {
              // otherwise add the value to the end of the array
              obj[key].push(paramValue);
            }
          } else {
            // we're dealing with a string
            if (!obj[paramName]) {
              // if it doesn't exist, create property
              obj[paramName] = paramValue;
            } else if (obj[paramName] && typeof obj[paramName] === "string") {
              // if property does exist and it's a string, convert it to an array
              obj[paramName] = [obj[paramName]];
              obj[paramName].push(paramValue);
            } else {
              // otherwise add the property
              obj[paramName].push(paramValue);
            }
          }
        }
      }

      return obj;
    },
    loginByToken(tokenHash, routeHash) {
      this.loading = true;
      // console.log(tokenHash);
      const token = atob(tokenHash);
      // console.log(token);
      const route = atob(routeHash);
      let user = {};
      this.$http.defaults.headers.common["Authorization"] = "Bearer " + token;
      this.$http.get(this.$store.state.urlBase + "user").then((response) => {
        user = response.data;
        this.$session.start();
        this.$session.set("user", response.data);
        this.$store.state.user = response.data;
        this.$store.commit("setUser", response.data);
        window.localStorage.setItem("user", response.data);
        window.user = response.data;
        window.getApp.$emit("APP_SAIDAS_CLIENTE_GET");
        this.loading = false;
        this.$router.push("/" + route);
      });
    },
    openThemeSettings() {
      this.$vuetify.goTo(0);
      this.rightDrawer = !this.rightDrawer;
    },
    logout: function () {
      window.localStorage.removeItem("user");
      this.$router.push("/login");
      this.$session.destroy();
      delete this.user;
    },
  },
};
</script>

<style lang="stylus" scoped>
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

.page-wrapper {
  min-height: calc(100vh - 64px - 50px - 81px);
}
.v-btn__content{
  cursor: pointer;
}
</style>
