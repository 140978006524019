<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex sm12>
                <h3>Categorias de itens do almoxarifado</h3>
                </v-flex>
                <v-flex lg12>
                    <v-card>
                        <v-toolbar flat color="white">
                        <!-- <v-toolbar-title>My CRUD</v-toolbar-title>
                            <v-divider
                                class="mx-2"
                                inset
                                vertical
                            ></v-divider> -->
                            <v-spacer></v-spacer>
                            <categoria-modal></categoria-modal>
                        </v-toolbar>
                        <v-toolbar card color="white">
                        <v-text-field
                        flat
                        solo
                        prepend-icon="search"
                        placeholder="Digite algo"
                        v-model="search"
                        hide-details
                        class=""
                        ></v-text-field>     
                        <v-btn icon>
                            <v-icon>filter_list</v-icon>
                        </v-btn>         
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                        <v-data-table
                            :headers="complex.headers"
                            :search="search"
                            :items="complex.items"
                            :rows-per-page-items="[10,25,50,{text:'All','value':-1}]"
                            class="elevation-1"
                            item-key="name"
                            v-model="complex.selected"
                            >
                            <template slot="items" slot-scope="props">
                            <td>{{ props.item.id }}</td>
                            <td>{{ props.item.descricao }}</td>
                            <td>{{ props.item.CdUsuario }}</td>
                            <td>{{ props.item.created_at | formatDate('DD/MM/YYYY HH:mm:ss')}}</td>
                            <td>
                                <v-btn depressed outline icon fab dark color="primary" small @click.native="editar(props.item)">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                <!-- <v-btn depressed outline icon fab dark color="pink" small>
                                <v-icon>delete</v-icon>
                                </v-btn> -->
                            </td>
                            </template>
                        </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>       
        </v-container>
    </div>
</template>
<script>
import toastr from 'toastr'
import CategoriaModal from '@/components/almoxarifado/Categoria.vue'
import * as moment from "moment";
import 'moment/locale/pt-br'
moment.locale('pt-BR')

export default {
  components:{
      CategoriaModal
  },
  data () {
    return {
      dialog: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Descrição',
            value: 'descricao'
          },
          {
            text: 'Criado por',
            value: 'CdUsuario'
          },
          {
            text: 'Data de criação',
            value: 'created_at'
          },
          {
            text: 'Ações',
            value: ''
          }
        ],
        items: []
      },
    }
  },
   methods: {
       getCategorias () {
           toastr.success('Atualizando categorias de itens do almoxarifado...','Varejão da Sucata')
           this.$http.get(this.$store.state.urlBase+'almoxarifado/categoria')
                        .then(response => {
                            this.complex.items = response.data
                        })
       },
       editar (categoria) {
           this.$store.state.categoria = categoria
           this.$store.state.dialogCategoria = true
       }
   },
  created () {
      this.getCategorias ()
      delete window.getApp._events.APP_CATEGORIA_UPDATED
      delete window.getApp._events.APP_RECORD_UPDATED
      window.getApp.$on('APP_CATEGORIA_UPDATED',this.getCategorias)
      window.getApp.$on('APP_RECORD_UPDATED',this.getCategorias)
  },
  updated () {
    //   this.getCategorias ()
  },
  filters: {
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  }
}
</script>