<template>
  <v-layout row class="mt-3">
    <v-flex xs12 sm12>
      <v-card>
        <v-list two-line>
          <template v-for="(item, index) in items">
            <v-list-tile :key="item.title" avatar ripple @click="item.action">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                <v-list-tile-sub-title class="text--primary">{{
                  item.headline
                }}</v-list-tile-sub-title>
                <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-flex>
    <dialog-status></dialog-status>
    <DistribuirNFeCliente></DistribuirNFeCliente>
    <ConsultaRetornoDoLoteNFeByNfe></ConsultaRetornoDoLoteNFeByNfe>
  </v-layout>
</template>
<script>
import DialogStatus from "@/components/DialogStatus.vue";
import DistribuirNFeCliente from "@/components/DistribuirNFeCliente.vue";
import ConsultaRetornoDoLoteNFeByNfe from "@/components/ConsultaRetornoDoLoteNFeByNfe.vue";

export default {
  components: {
    DialogStatus,
    DistribuirNFeCliente,
    ConsultaRetornoDoLoteNFeByNfe,
  },
  data() {
    return {
      selected: [],
      statusNfeRetorno: {},
      retorno: { xMotivo: "", cStat: "" },
      items: [
        {
          action: this.statusNfe,
          headline: "Consulta o status dos serviços da Sefaz",
          title: "Status dos serviços NF-e",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/NfeStatusServico",
        },
        {
          action: this.GeraReenviaLoteNfeSaida,
          headline: "Gera e envia os lotes de notas de saída",
          title: "Enviar notas de Saida",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/GeraReenviaLoteNfeSaida",
        },
        {
          action: this.GeraReenviaLoteNfeEntrada,
          headline: "Gera e envia os lotes de notas de entrada",
          title: "Enviar notas de Entrada",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/GeraReenviaLoteNfeEntrada",
        },
        {
          action: this.DistribuirNFeCliente,
          headline: "Gera o danfe e o xml para envio por e-mail",
          title: "Distribuir NF-e Cliente",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/DistribuirNFeCliente",
        },
        {
          action: this.NfeCancelarServico,
          headline: "Envia as Solicitações de Cancelamento",
          title: "Cancelamento NF-e",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/NfeCancelarServico",
        },
        {
          action: this.NfeInutilizacao,
          headline: "Envia as Solicitações de Inutilização",
          title: "Inutilização NF-e",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/NfeInutilizacao",
        },
        {
          action: this.NfeCartaCorrecao,
          headline: "Envia as Cartas de Correção",
          title: "Cartas de Correção NF-e",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/NfeCartaCorrecao",
        },
        {
          action: this.GeraReenviaLoteNaoEnviado,
          headline: "Reenvia os lotes de NF-e não enviados",
          title: "Reenviar Lotes de NF-e",
          subtitle: "https://api.varejaodasucata.com.br/sefaz/GeraReenviaLoteNaoEnviado",
        },
        {
          action: this.ConsultaRetornoDoLoteNFeByNfe,
          headline: "Consulta Retorno do Lote informando a numeração da NFe",
          title: "Consulta Retorno do Lote de NF-e",
          subtitle:
            "https://api.varejaodasucata.com.br/sefaz/ConsultaRetornoDoLoteNFeByNfe",
        },
        {
          action: this.diretoria,
          headline:
            "Relatório diário da Diretoria Matriz com toda a movimentação do dia e consolidada do mês corrente até o momento",
          title: "Relatório diário da Diretoria Matriz",
          subtitle: "https://api.varejaodasucata.com.br/diretoria",
        },
        {
          action: this.diretoriaFilial,
          headline:
            "Relatório diário da Diretoria Filial com toda a movimentação do dia e consolidada do mês corrente até o momento",
          title: "Relatório diário da Diretoria Filial",
          subtitle: "https://api.varejaodasucata.com.br/diretoriaFilial",
        },
      ],
    };
  },
  methods: {
    toggle(index) {
      const i = this.selected.indexOf(index);
    },
    nullable() {
      console.log(this);
    },
    diretoria() {
      axios
        .get("https://api.varejaodasucata.com.br/diretoria")
        .then((response) =>
          this.retornoDiretoria(response.data, "Relatório Diretoria Matriz")
        );
    },
    diretoriaFilial() {
      axios
        .get("https://api.varejaodasucata.com.br/diretoriaFilial")
        .then((response) =>
          this.retornoDiretoria(response.data, "Relatório Diretoria Filial")
        );
    },
    retornoDiretoria(retorno, titulo) {
      // retorno.xMotivo = "Enviado";
      // retorno.cStat = "100";
      this.$store.commit("statusNfeTitulo", titulo);
      this.$store.commit("dialogStatusNfe", true);
      this.$store.commit("statusNfeRetorno", retorno);
    },
    retornoStatusNfe(retorno) {
      this.$store.commit("statusNfeTitulo", "Status do serviço NF-e");
      this.$store.commit("dialogStatusNfe", true);
      this.$store.commit("statusNfeRetorno", retorno);
    },
    GeraReenviaLoteNfeSaidaModal(retorno) {
      this.$store.commit("dialogStatusNfe", true);
      this.$store.commit("statusNfeRetorno", retorno);

      if (retorno.cStat) {
        this.$store.commit("statusNfeTitulo", "Lote de Saída Gerado e enviado");
      } else {
        this.$store.commit("statusNfeTitulo", "Lote de Saída Vazio");
      }
    },
    GeraReenviaLoteNfeEntradaModal(retorno) {
      this.$store.commit("dialogStatusNfe", true);
      this.$store.commit("statusNfeRetorno", retorno);

      if (retorno.cStat) {
        this.$store.commit("statusNfeTitulo", "Lote de Entrada Gerado e enviado");
      } else {
        this.$store.commit("statusNfeTitulo", "Lote de Entrada Vazio");
      }
    },
    ServicoSolicitadoModel(retorno) {
      console.log(retorno);

      if (Array.isArray(retorno)) {
        retorno.forEach((element, index, array) => {
          console.log(retorno[index].retEvento.infEvento.xMotivo);
          this.retorno.xMotivo = retorno[index].retEvento.infEvento.xMotivo;
          this.retorno.cStat = retorno[index].retEvento.infEvento.cStat;
        });
      } else {
        this.retorno = retorno;
      }
      this.$store.commit("dialogStatusNfe", true);
      // this.$store.commit("statusNfeRetorno", { xMotivo: "", cStat: "" });
      this.$store.commit("statusNfeRetorno", this.retorno);
      this.$store.commit("statusNfeTitulo", "Serviço Solicitado");
      this.retorno = { xMotivo: "", cStat: "" };
    },
    statusNfe() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/NfeStatusServico")
        .then((response) => this.retornoStatusNfe(response.data));
    },
    GeraReenviaLoteNfeEntrada() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/GeraReenviaLoteNfeEntrada")
        .then((response) => this.GeraReenviaLoteNfeEntradaModal(response.data));
    },
    GeraReenviaLoteNfeSaida() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/GeraReenviaLoteNfeSaida")
        .then((response) => this.GeraReenviaLoteNfeSaidaModal(response.data));
    },
    DistribuirNFeCliente() {
      this.$store.state.dialogDistribuirNFeCliente = true;
    },
    ConsultaRetornoDoLoteNFeByNfe() {
      this.$store.state.dialogConsultaRetornoDoLoteNFeByNfe = true;
    },
    GeraReenviaLoteNaoEnviado() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/GeraReenviaLoteNaoEnviado")
        .then((response) => this.ServicoSolicitadoModel(response.data));
    },
    NfeCancelarServico() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/NfeCancelarServico")
        .then((response) => this.ServicoSolicitadoModel(response.data));
    },
    NfeInutilizacao() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/NfeInutilizacao")
        .then((response) => this.ServicoSolicitadoModel(response.data));
    },
    NfeCartaCorrecao() {
      axios
        .get("https://api.varejaodasucata.com.br/sefaz/NfeCartaCorrecao")
        .then((response) => this.ServicoSolicitadoModel(response.data));
    },
  },
};
</script>
