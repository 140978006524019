<template>
    <v-dialog v-model="$store.state.dialogPerfil" persistent max-width="500px">
        <!-- <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Usuário
        </v-btn> -->
        <v-card>
            <v-toolbar card prominent extended color="primary" dark="">
                <v-toolbar-title class="body-2">Perfil de usuário</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="$store.state.dialogPerfil = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
			<v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation><!--v-model="valid" ref="form" lazy-validation -->
                    <v-layout row wrap>
                        <v-flex lg12 sm12>
                            <v-text-field label="Nome Completo" v-model="$store.state.userEdit.NmCompleto">
                            </v-text-field>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Nome de Guerra" v-model="$store.state.userEdit.NmGuerra">
                            </v-text-field>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Usuário" v-model="$store.state.userEdit.CdUsuario">
                            </v-text-field>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Senha" v-model="$store.state.userEdit.password" type="password">
                            </v-text-field>
                        </v-flex>
                    </v-layout>        
                </v-form>
            </v-card-text>  
            <v-card-actions class="pb-3">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="cancelar()">Cancelar</v-btn>
                <!-- <v-btn color="primary"  @click.native="salvar()" v-if="!$store.state.userEdit.id">Salvar</v-btn> -->
                <v-btn color="primary"  @click.native="update()">Atualizar</v-btn>
            </v-card-actions>	
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:'Perfil',
    data () {
        return {
            dialog: false,
            valid: true,
        }
    },
    methods : {
        // salvar (){
        //     let user = this.$store.state.userEdit
        //     this.$http.post(this.$store.state.urlBase+'user',user)
        //         .then(response => {
        //            this.atualizar()
        //     })
        // },
        cancelar() {
            this.$store.state.dialogPerfil = false
            this.$store.state.userEdit = {}
        },
        update (){
            let user = this.$store.state.userEdit
            this.$http.put(this.$store.state.urlBase+'user',user)
                 .then(response => {
                    this.atualizar()
            })
        },
        atualizar () {
            this.$store.state.userEdit = {}
            window.getApp.$emit('APP_USER_UPDATED')
            this.$store.state.dialogPerfil = false
        }
    },
    created () {
        // console.log(!this.$store.state.categoria.id)
    }
}
</script>
