<template lang="html">
<div>
  <v-text-field 
    type="tel"
     :v-model="formattedValue"
     :value="formattedValue"
     @change="change"
     @input="change"
     class="v-money"
     v-money="{precision, decimal, thousands, prefix, suffix}"
     :label="label" ></v-text-field> {{formattedValue}}
  <!-- <input type="tel"
         :value="formattedValue"
         @change="change"
         v-money="{precision, decimal, thousands, prefix, suffix}"
         class="v-money" /> -->
         </div>
</template>

<script>
import money from './directive'
import defaults from './options'
import {format, unformat} from './utils'

export default {
  name: 'Moeda',
  props: {
    label: {
      required: false,
      type: [Number, String],
      default: ''
    },
    value: {
      required: true,
      type: [Number, String],
      default: 0
    },
    masked: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: () => defaults.precision
    },
    decimal: {
      type: String,
      default: () => defaults.decimal
    },
    thousands: {
      type: String,
      default: () => defaults.thousands
    },
    prefix: {
      type: String,
      default: () => defaults.prefix
    },
    suffix: {
      type: String,
      default: () => defaults.suffix
    }
  },

  directives: {money},

  data () {
    return {
      valorExibido: '',
      formattedValue:''
    }
  },
  computed: {
    // formattedValue(){
    //   let valor = format(this.value,this.$props)
    //   console.log('valor formatado: '+valor)
    //   this.valorExibido = valor
    //   return valor
    // }
  },
updated (){

},
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        // console.log('newValue: '+newValue)
        // console.log('oldValue: '+oldValue)

        var formatted = format(newValue, this.$props)
        // console.log('formatted: '+formatted)
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted
        }
      }
    }
  },

  methods: {
    change (value) {
      var vm =   this
      // console.log(value)
      // console.log(this)
      // console.log(vm._events)
      

      this.$emit('input', this.masked ? value: unformat(value, this.precision))
    }
  }
}
</script>
