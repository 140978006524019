import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialogStatusNfe: false,
    dialogDistribuirNFeCliente: false,
    dialogConsultaRetornoDoLoteNFeByNfe: false,
    dialogCategoria: false,
    dialogItem: false,
    dialogInventario:[],
    dialogEntrada: false,
    dialogSaida:false,
    dialogRelatorioSaida:false,
    dialogFornecedor:false,
    dialogResumoDiario:false,
    dialogCliente:false,
    dialogMTR:false,
    dialogDanfe:false,
    dialogPrecoPadrao:false,
    dialogUser: false,
    dialogPermissao: false,
    dialogPerfil: false,
    metodoPagamento: [],
    statusNfeRetorno: {
      xMotivo: '',
      cStat: ''
    },
    statusNfeTitulo: '',
    users: [],
    userEdit: {
      setor: {}
    },
    permissionEdit: {

    },
    user: {
      token: false
    },
    resumoDia:[],
    unidades: [],
    itens: [],
    itensSucata: [],
    setores: [],
    compradores: [],
    fornecedores:[],
    clientes:[],
    clientesbalanca:[],
    tiposucata:[],
    impostonfe:[],
    tiposucatasaida:[],
    tiposfrete:[],
    funcionarios:[],
    precoMedioAtual: '0',
    estoqueFisico: '0',
    estoqueContabil: '0',
    saidasCliente: [],
    saidasClienteQuantidade: [],
    pesoSaidasCliente: [],
    pesoSaidaEntrada: [],
    estoque: [],
    precoPadrao: [],
    precoPadraoSaida: [],
    mfesVinculados: [],
    categoria: {},
    item: {},
    teste: 0,
    entrada:{},
    fornecedor:{},
    cliente:{},
    mtr:{},
    danfe:{},
    precoPadraoItem:{
      CdItem: 111
    },
    saida:{},
    urlBase: 'https://api.varejaodasucata.com.br/api/',
    urlBaseWeb: 'https://api.varejaodasucata.com.br/',
    CdLoja: 1,
    loja:{},
    eventos:[],
    ccustos:[],
    apuracaoICMS:[
      {descricao:'Crédito do Imposto',valor:1},
      {descricao:'Isentas ou não Tributadas',valor:2},
      {descricao:'Outras',valor:3},
    ]
  },
  mutations: {
    dialogStatusNfe (state, value) {
      state.dialogStatusNfe = value
    },
    statusNfeRetorno (state, value) {
      state.statusNfeRetorno = value
    },
    statusNfeTitulo (state, value) {
      state.statusNfeTitulo = value
    },
    setUser (state, value) {
      state.user = value
    },
    abrirModalEntrada(state, value) {
      state.entrada = value
      state.dialogEntrada = true
    },
    abrirModalFornecedor(state, value) {
      state.fornecedor = value
      state.dialogFornecedor = true
    },
    abrirModalResumoDiaro(state) {
      state.dialogResumoDiario = true
    },
    abrirModalCliente(state, value) {
      state.cliente = value
      state.dialogCliente = true
    },
    abrirModalMTR(state, value) {
      state.mtr = value
      state.dialogMTR = true
    },
    abrirModalViewMTR(state, value) {
      state.mtr = value
      state.dialogViewMTR = true
    },
    abrirModalDanfe(state, value) {
      state.danfe = value
      state.dialogDanfe = true
    },
    abrirModalPermissoes(state, value) {
      state.permissionEdit = value
      state.dialogPermissao = true
    },
    abrirModalPrecoPadrao(state, value) {
      state.precoPadraoItem = value
      state.dialogPrecoPadrao = true
    },
    fecharModalPrecoPadrao(state) {
      state.precoPadraoItem = {
        CdItem: 111
      }
      state.dialogPrecoPadrao = false
    },
    abrirModalSaida(state, value) {
      state.saida = value
      state.dialogSaida = true
    },
    fecharModalEntrada(state) {
      state.entrada = {}
      state.dialogEntrada = false
    },
    fecharModalResumoDiario(state) {
      state.dialogResumoDiario = false
    },
    fecharModalFornecedor(state) {
      state.fornecedor = {}
      state.dialogFornecedor = false
    },
    fecharModalCliente(state) {
      state.cliente = {}
      state.dialogCliente = false
    },
    fecharModalMTR(state) {
      state.mtr = {}
      state.dialogMTR = false
    },
    fecharModalViewMTR(state) {
      state.mtr = {}
      state.dialogViewMTR = false
    },
    fecharModalDanfe(state) {
      state.danfe = {}
      state.dialogDanfe = false
      state.danfe.exibirPDF = false;
    },
    fecharModalRelatorioSaida(state){
      state.dialogRelatorioSaida = false
    },
    fecharModalSaida(state) {
      state.saida = {}
      state.dialogSaida = false
    },
    fornecedores(state, value) {
      state.fornecedores = value
    },
    funcionarios(state, value) {
      state.funcionarios = value
    },
    itens(state, value) {
      state.itens = value
    },
    itensSucata(state, value) {
      state.itensSucata = value
    }
  },
  actions: {

  },
  getters: {
    user: state => {
      return state.user
    },
    dialogEntrada: state => {
      return state.dialogEntrada
    },
    dialogSaida: state => {
      return state.dialogSaida
    },
    entrada: state => {
      return state.entrada
    },
    saida: state => {
      return state.saida
    },
    fornecedores: state => {
      return state.fornecedores
    },
    tiposucata: state => {
      return state.tiposucata
    },
    tiposucatasaida: state => {
      return state.tiposucatasaida
    },
    clientesbalanca: state => {
      return state.clientesbalanca
    },
    tiposfrete: state => {
      return state.tiposfrete
    },
    funcionarios: state => {
      return state.funcionarios
    },
    itens: state => {
      return state.itens
    },
    itensSucata: state => {
      return state.itensSucata
    },
    saidasCliente: state => {
      return state.saidasCliente
    },
    saidasClienteQuantidade: state => {
      return state.saidasClienteQuantidade
    },
    compradores: state => {
      return state.compradores
    },
    exibirPDF: state => {
      return state.danfe.exibirPDF
    }
  }
})
