<template>
    <v-dialog v-model="$store.state.dialogCategoria" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Nova Categoria
        </v-btn>
        <v-card>
            <v-card-title>
                <span class="headline">Categoria</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Descrição da Categoria *" required v-model="$store.state.categoria.descricao"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <small>* campo obrigatório</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="$store.state.dialogCategoria = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.state.categoria.id">Salvar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="update()" v-else>Atualizar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
export default {
    data () {
        return {
            dialog: false,
        }
    },
    methods : {
        salvar (){
            let categoria = this.$store.state.categoria
            this.$http.post(this.$store.state.urlBase+'almoxarifado/categoria',categoria)
                .then(response => {
                   if(typeof response.data.errors !== 'undefined'){
                        //Erros de Validação
                        for (var error in response.data.errors) {
                            toastr.error(response.data.errors[error],'Cadastro de Itens')
                        }
                   }else{
                       this.atualizar()
                   }
            })
        },
        update (){
            let categoria = this.$store.state.categoria
            this.$http.put(this.$store.state.urlBase+'almoxarifado/categoria',categoria)
                .then(response => {
                   if(typeof response.data.errors !== 'undefined'){
                        //Erros de Validação
                        for (var error in response.data.errors) {
                            toastr.error(response.data.errors[error],'Cadastro de Itens')
                        }
                   }else{
                       this.atualizar()
                   }
            })
        },
        atualizar () {
            this.$store.state.categoria = {}
            window.getApp.$emit('APP_CATEGORIA_UPDATED')
            this.$store.state.dialogCategoria = false
        }
    },
    created () {
        // console.log(!this.$store.state.categoria.id)
    }
}
</script>
