<template>
  <v-layout row justify-center>
    <v-dialog v-model="$store.state.dialogDistribuirNFeCliente" persistent>
      <v-card>
        <v-card title>
           <v-toolbar card dark color="primary">
            <v-btn icon dark @click.native="$store.state.dialogDistribuirNFeCliente = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Distribuir NF-e Cliente</v-toolbar-title>
          </v-toolbar>
        </v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field label="Número da NF-e" v-model="nrnf"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <small>Escolha o modo de envio</small>
        </v-card-text>
        <v-card-actions xs12 sm12 md12>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click.native="DistribuirNFeClienteEnviar()" :disabled="nrnf!=''">Todas</v-btn>
          <v-btn color="blue darken-1" flat @click.native="DistribuirNFeClienteEnviarSelecionada()" :disabled="nrnf==''">Selecionada</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  export default {
    name: 'DistribuirNFeCliente',
    data: () => ({
        nrnf: ''
    //   dialog: false
    }),
    methods: {
        ServicoSolicitadoModel (retorno) {
            console.log(retorno)
            this.$store.commit('dialogStatusNfe',true)
            this.$store.commit('statusNfeRetorno', {xMotivo: '', cStat: ''})
            this.$store.commit('statusNfeTitulo','Serviço Solicitado')
        },
        DistribuirNFeClienteEnviarSelecionada (){
            this.$store.state.dialogDistribuirNFeCliente = false
            axios
            .get('https://api.varejaodasucata.com.br/sefaz/DistribuirNFeCliente/'+this.nrnf)
            .then(response => (this.ServicoSolicitadoModel(response.data)))
        },
        DistribuirNFeClienteEnviar (){
            this.$store.state.dialogDistribuirNFeCliente = false
            axios
            .get('https://api.varejaodasucata.com.br/sefaz/DistribuirNFeCliente')
            .then(response => (this.ServicoSolicitadoModel(response.data)))
        }
                
    }

  }
</script>