/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/pages/Dashboard.vue'
import ServicosNfe from '@/pages/ServicosNfe.vue'
import NProgress from 'nprogress'
import NotFound from '@/pages/NotFound.vue'
import CategoriasAlmox from '@/pages/almoxarifado/Categorias.vue'
import API from "@/api/";
import Hash from "@/Hash.vue";


Vue.use(Router)
const router = new Router({
  base: '/',
  mode: 'hash',
  meta: { breadcrumb: true, public: false },
  component: Dashboard,
  linkActiveClass: 'active',
  routes: [
    {
      path: '*',
      meta: {
        public: true
      },
      redirect: {
        path: '/404'
      }
    },
    {
      path: '/404',
      meta: {
        public: true
      },
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { breadcrumb: true, public: false },
      component: Dashboard
    },
    {
      path: '/ServicosNfe',
      name: 'ServicosNfe',
      meta: { breadcrumb: true, public: false },
      component: ServicosNfe
    },
    {
      path: '/almoxarifado/categorias',
      meta: { breadcrumb: true, public: false },
      name: 'pages/almoxarifado/categorias',
      component: CategoriasAlmox
    },
    {
      path: '/almoxarifado/itens',
      meta: { breadcrumb: true, public: false },
      name: 'pages/almoxarifado/itens',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/almoxarifado/Itens.vue`
      )
    },
    {
      path: '/almoxarifado/entradas',
      meta: { breadcrumb: true, public: false },
      name: 'pages/almoxarifado/entradas',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/almoxarifado/Entradas.vue`
      )
    },
    {
      path: '/almoxarifado/entrada',
      meta: { breadcrumb: true, public: false },
      name: 'pages/almoxarifado/entradas/entrada',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/almoxarifado/Entrada.vue`
      )
    },
    {
      path: '/almoxarifado/saidas',
      meta: { breadcrumb: true, public: false },
      name: 'pages/almoxarifado/saidas',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/almoxarifado/Saidas.vue`
      )
    },
    {
      path: '/comercial/fornecedores-sucata',
      meta: { breadcrumb: true, public: false },
      name: 'pages/comercial/fornecedores-sucata',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/comercial/Fornecedores.vue`
      )
    },
    {
      path: '/financeiro/fornecedores',
      meta: { breadcrumb: true, public: false },
      name: 'pages/financeiro/fornecedores',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/financeiro/Fornecedores.vue`
      )
    },
    {
      path: '/comercial/clientes',
      meta: { breadcrumb: true, public: false },
      name: 'pages/comercial/clientes',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/comercial/Clientes.vue`
      )
    },
    {
      path: '/comercial/saidas',
      meta: { breadcrumb: true, public: false },
      name: 'pages/comercial/saidas',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/comercial/Saidas.vue`
      )
    },
    {
      path: '/contabilidade/ctes',
      meta: { breadcrumb: true, public: false },
      name: 'pages/contabilidade/ctes',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/contabilidade/Ctes.vue`
      )
    },
    {
      path: '/contabilidade/nfesdest',
      meta: { breadcrumb: true, public: false },
      name: 'pages/contabilidade/nfesdest',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/contabilidade/NfesDestinadas.vue`
      )
    },
    {
      path: '/contabilidade/numeracaoNfe',
      meta: { breadcrumb: true, public: false },
      name: 'pages/contabilidade/numeracaoNfe',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/contabilidade/NfesEmitidas.vue`
      )
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      meta: { breadcrumb: true, public: false, permission: '10|1|2|34' },
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/usuario/Usuarios.vue`
      )
    },
    {
      path: '/configuracoes/permissoes',
      meta: { breadcrumb: true, public: false, permission: '10|1|2|34' },
      name: 'pages/configuracoes/permissoes',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/configuracoes/Permissoes.vue`
      )
    },
    {
      path: '/403',
      meta: {
        public: true
      },
      name: 'AccessDenied',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/Deny.vue`
      )
    },
    {
      path: '/500',
      meta: {
        public: true
      },
      name: 'ServerError',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/Error.vue`
      )
    },
    {
      path: '/login',
      meta: {
        public: true
      },
      name: 'Login',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        '@/pages/Login.vue'
      )
    },
    {
      path: '/login/:hash/:route',
      meta: {
        public: true
      },
      name: 'Token',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        '@/Hash.vue'
      )
    },
    {
      path: '/media',
      meta: {},
      name: 'Media',
      props: (route) => ({ type: route.query.type }),
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        '@/pages/Media.vue'
      )
    },
    {
      path: '/chat',
      meta: {
        public: true
      },
      name: 'Chat',
      component: () => import(
        /* webpackChunkName: "routes" */
        '@/components/chat/ChatLayout.vue'
      ),
      redirect: {
        path: '/chat/messaging'
      },
      children: [
        {
          path: '/chat/messaging/:uuid?',
          meta: {
            public: true
          },
          name: 'ChatMessaging',
          props: true,
          components: {
            default: () => import(
              /* webpackChunkName: "routes" */
              /* webpackMode: "lazy-once" */
              `@/components/chat/ChatMessaging.vue`
            ),
          }
        },
        {
          path: '/chat/contact/:uuid?',
          meta: {
            public: true
          },
          name: 'ChatContact',
          components: {
            default: () => import(
              /* webpackChunkName: "routes" */
              /* webpackMode: "lazy-once" */
              `@/components/chat/ChatContact.vue`
            )
          }
        }
      ]
    },
    {
      path: '/mail',
      meta: {
        public: false
      },
      name: 'Mail',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/components/email/Layout.vue`
      ),
      redirect: {
        path: '/mail/all'
      },
      children: [
        {
          path: '/mail/:mailType',
          meta: {
            public: true
          },
          name: 'MailIndex',
          component: () => import(
            /* webpackChunkName: "routes" */
            /* webpackMode: "lazy-once" */
            `@/components/email/List.vue`
          )
        },
        {
          path: '/mail/0/:uuid',
          meta: {
            public: true
          },
          name: 'MailDetail',
          component: () => import(
            /* webpackChunkName: "routes" */
            /* webpackMode: "lazy-once" */
            '@/components/email/Reply.vue'
          )
        }
      ]
    },
    {
      path: '/components/alert',
      meta: { breadcrumb: true },
      name: 'components/alerts',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        '@/pages/ui/Alert.vue'
      )
    },
    {
      path: '/components/avatar',
      meta: { breadcrumb: true },
      name: 'components/avatars',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Avatar.vue`
      )
    },
    {
      path: '/components/badge',
      meta: { breadcrumb: true },
      name: 'components/badges',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Badge.vue`
      )
    },
    {
      path: '/components/button',
      meta: { breadcrumb: true },
      name: 'components/buttons',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Button.vue`
      )
    },
    {
      path: '/components/parallax',
      meta: { breadcrumb: true },
      name: 'components/parallax',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Parallax.vue`
      )
    },
    {
      path: '/components/snackbar',
      meta: { breadcrumb: true },
      name: 'components/snackbar',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Snackbar.vue`
      )
    },
    {
      path: '/components/chip',
      meta: { breadcrumb: true },
      name: 'components/chips',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Chip.vue`
      )
    },
    {
      path: '/components/card',
      meta: { breadcrumb: true },
      name: 'components/cards',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Cards.vue`
      )
    },
    {
      path: '/components/table',
      meta: { breadcrumb: true },
      name: 'components/tables',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Tables.vue`
      )
    },
    {
      path: '/components/carousel',
      meta: { breadcrumb: true },
      name: 'components/carousels',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Carousels.vue`
      )
    },
    {
      path: '/components/dialog',
      meta: { breadcrumb: true },
      name: 'components/dialogs',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Dialogs.vue`
      )
    },
    {
      path: '/components/icon',
      meta: { breadcrumb: true },
      name: 'components/icons',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Icon.vue`
      )
    },
    {
      path: '/components/progress',
      meta: { breadcrumb: true },
      name: 'components/progress',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Progress.vue`
      )
    },
    {
      path: '/components/slider',
      meta: { breadcrumb: true },
      name: 'components/sliders',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Slider.vue`
      )
    },
    {
      path: '/components/tooltip',
      meta: { breadcrumb: true },
      name: 'components/tooltips',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Tooltip.vue`
      )
    },
    {
      path: '/components/pagination',
      meta: { breadcrumb: true },
      name: 'components/paginations',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Pagination.vue`
      )
    },
    {
      path: '/pickers/datepicker',
      meta: { breadcrumb: true },
      name: 'pickers/datepicker',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Datepicker.vue`
      )
    },
    {
      path: '/components/typography',
      meta: { breadcrumb: true },
      name: 'components/typography',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Typography.vue`
      )
    },
    {
      path: '/components/color',
      meta: { breadcrumb: true },
      name: 'components/color',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Colors.vue`
      )
    },
    {
      path: '/pickers/timepicker',
      meta: { breadcrumb: true },
      name: 'pickers/timepicker',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/ui/Timepicker.vue`
      )
    },
    {
      path: '/layout/bottomsheets',
      meta: { breadcrumb: true },
      name: 'components/bottom-sheets',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/BottomSheets.vue`
      )
    },
    {
      path: '/layout/expansion-panel',
      meta: { breadcrumb: true },
      name: 'components/expansion-panels',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/ExpansionPanels.vue`
      )
    },
    {
      path: '/layout/footer',
      meta: { breadcrumb: true },
      name: 'components/footer',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Footers.vue`
      )
    },
    {
      path: '/layout/timeline',
      meta: { breadcrumb: true },
      name: 'components/timeline',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Timeline.vue`
      )
    },
    {
      path: '/layout/list',
      meta: { breadcrumb: true },
      name: 'components/lists',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Lists.vue`
      )
    },
    {
      path: '/layout/toolbar',
      meta: { breadcrumb: true },
      name: 'components/toolbar',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Toolbar.vue`
      )
    },
    {
      path: '/layout/jumbotron',
      meta: { breadcrumb: true },
      name: 'components/jumbotrons',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Jumbotrons.vue`
      )
    },
    {
      path: '/layout/menu',
      meta: { breadcrumb: true },
      name: 'components/menus',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Menus.vue`
      )
    },
    {
      path: '/layout/navigation-drawer',
      meta: { breadcrumb: true },
      name: 'components/navigation-drawers',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/NavigationDrawers.vue`
      )
    },
    {
      path: '/layout/tabs',
      meta: { breadcrumb: true },
      name: 'components/tabs',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/layout/Tabs.vue`
      )
    },
    {
      path: '/forms/basic',
      meta: { breadcrumb: true },
      name: 'components/basic-forms',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/form/BasicForms.vue`
      )
    },
    {
      path: '/forms/selects',
      meta: { breadcrumb: true },
      name: 'components/selects',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/form/Selects.vue`
      )
    },
    {
      path: '/forms/editor',
      meta: { breadcrumb: true },
      name: 'components/editors',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/form/Editors.vue`
      )
    },
    {
      path: '/forms/selection-controls',
      meta: { breadcrumb: true },
      name: 'components/selection-controls',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/form/SelectionControls.vue`
      )
    },
    {
      path: '/forms/text-fields',
      meta: { breadcrumb: true },
      name: 'components/text-fields',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/form/TextFields.vue`
      )
    },
    {
      path: '/forms/steppers',
      meta: { breadcrumb: true },
      name: 'components/steppers',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/form/Steppers.vue`
      )
    },
    {
      path: '/widgets/social',
      meta: { breadcrumb: true },
      name: 'components/social',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/widgets/Social.vue`
      )
    },
    {
      path: '/widgets/post',
      meta: { breadcrumb: true },
      name: 'components/widget-post',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/widgets/Post.vue`
      )
    },
    {
      path: '/widgets/statistic',
      meta: { breadcrumb: true },
      name: 'components/statistic',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/widgets/Statistic.vue`
      )
    },
    {
      path: '/widgets/chart',
      meta: { breadcrumb: true },
      name: 'components/chart',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/widgets/Chart.vue`
      )
    },
    {
      path: '/widgets/list',
      meta: { breadcrumb: true },
      name: 'components/widget-list',
      component: () => import(
        /* webpackChunkName: "routes" */
        /* webpackMode: "lazy-once" */
        `@/pages/widgets/List.vue`
      )
    }
  ]
})
// router gards
router.beforeEach((to, from, next) => {
  // console.log(typeof router.app.$store.getters === 'undefined')
  // console.log(to)

  let user = (typeof router.app.$store.getters === 'undefined') ? { token: false } : router.app.$store.getters.user
  /* Identifica a permissao em acessar a rota solicitada */
  if (user.token) {
    // console.log(user)
    let haystack = router.app.$store.getters.user.token ? router.app.$store.getters.user.setor.permissoes : []
    let AccessDenied = API.getAcessoRotaNegado(haystack, to.path, to.meta.public)

    // if (to.meta.permission) {
    // const permissoes = to.meta.permission.split('|')
    // const setor = user.setor.CdSetor + ''
    // const AccessDenied = permissoes.indexOf(setor)
    // console.log(setor)
    //console.log(AccessDenied !== -1)

    // }

  } 
  NProgress.start()
  next()
})

router.afterEach((to, from) => {
  // ...
  NProgress.done()
})


export default router
