<template>
  <v-layout row justify-center>
    <v-dialog v-model="$store.state.dialogConsultaRetornoDoLoteNFeByNfe" persistent>
      <v-card>
        <v-card title>
          <v-toolbar card dark color="primary">
            <v-btn
              icon
              dark
              @click.native="$store.state.dialogConsultaRetornoDoLoteNFeByNfe = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Consultar Retorno do Lote</v-toolbar-title>
          </v-toolbar>
        </v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field label="Número da NF-e" v-model="nrnf"></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <div>
                  {{ retorno }}
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <small>Escolha o modo de envio</small>
        </v-card-text>
        <v-card-actions xs12 sm12 md12>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" flat @click.native="DistribuirNFeClienteEnviar()" :disabled="nrnf!=''">Todas</v-btn> -->
          <v-btn
            color="blue darken-1"
            flat
            @click.native="ConsultaRetornoDoLoteNFeByNfeSelecionada()"
            :disabled="nrnf == ''"
            >Selecionada</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: "ConsultaRetornoDoLoteNFeByNfe",
  data: () => ({
    nrnf: "",
    retorno: "",
    //   dialog: false
  }),
  methods: {
    ServicoSolicitadoModel(retorno) {
      this.$store.commit("dialogStatusNfe", true);
      if (retorno[0]) {
        this.retorno = retorno[0];
        this.retorno.xMotivo = this.retorno.protNFe[0].infProt.xMotivo;
        this.retorno.cStat = this.retorno.protNFe[0].infProt.cStat;
      } else {
        this.retorno = retorno;
      }
      this.nrnf = "";
      // this.$store.commit("statusNfeRetorno", { xMotivo: "", cStat: "" });
      this.$store.commit("statusNfeRetorno", this.retorno);
      this.$store.commit("statusNfeTitulo", "Serviço Solicitado");
      this.retorno = { xMotivo: "", cStat: "" };
    },
    ConsultaRetornoDoLoteNFeByNfeSelecionada() {
      this.$store.state.dialogConsultaRetornoDoLoteNFeByNfe = false;
      axios
        .get(
          "https://api.varejaodasucata.com.br/sefaz/ConsultaRetornoDoLoteNFeByNfe/" +
            this.nrnf
        )
        .then((response) => this.ServicoSolicitadoModel(response.data));
    },
  },
};
</script>
