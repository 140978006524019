<template>
  <div class="text-xs-center">
    <v-dialog v-model="$store.state.dialogStatusNfe" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $store.state.statusNfeTitulo }}
        </v-card-title>

        <v-card-text>
          {{ $store.state.statusNfeRetorno.xMotivo }} -
          {{ $store.state.statusNfeRetorno.cStat }}

          <pre>
            {{ $store.state.statusNfeRetorno }}
          </pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="$store.state.dialogStatusNfe = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogStatus",
  data() {
    return {
      // dialog: false,
      // data:{
      // }
    };
  },
};
</script>
