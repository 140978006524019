/* eslint-disable no-console */

import { register } from 'register-service-worker'
import toastr from 'toastr'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      toastr.success("Uma nova versão do App está disponível!", "Varejão da Sucata")
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      window.location.reload(true)
      window.getApp.$emit('APP_LOGIN_SUCCESS')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
