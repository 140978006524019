import toastr from 'toastr'
export default [
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function (e) {
      this.loading = false
      this.$router.push('/')
    }
  },
  {
    name: 'APP_DASHBOARD_SUCCESS',
    callback: function (e) {
      this.loading = true
      // this.$router.push('/ServicosNfe')
      // this.$router.push('/')
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: 'green',
        text: 'Sucesso no logout.'
      }
      this.$session.destroy()
      this.$router.push('/login')
      // this.$router.replace({ path: '/login' })
    }
  },
  {
    name: 'APP_PERFIL',
    callback: function (e) {
      // this.snackbar = {
      //   show: true,
      //   color: 'green',
      //   text: 'Sucesso no logout.'
      // }
      this.$store.state.userEdit = this.$store.state.user
      // console.log(this.$store.state.userEdit)
      this.$store.state.dialogPerfil = true
      // console.log(this.$store.state.dialogPerfil)
      // this.$router.replace({ path: '/login' })
    }
  },
  {
    name: 'APP_PAGE_LOADED',
    callback: function (e) {
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function (e) {
      this.$router.push('/login')
      this.$message.error('Token has expired')
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg)
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg);
      this.$router.push('/forbidden')
    }
  },
  {
    name: 'APP_RESOURCE_DELETED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg)
    }
  },
  {
    name: 'APP_RESOURCE_UPDATED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg)
    }
  },
  {
    name: 'APP_UNIDADES_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'almoxarifado/unidades')
        .then(response => {
          this.$store.state.unidades = response.data
        })
    }
  },
  {
    name: 'APP_CATEGORIAS_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'almoxarifado/categoria')
        .then(response => {
          this.$store.state.categorias = response.data
        })
    }
  },
  {
    name: 'APP_ITENS_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'almoxarifado/item')
        .then(response => {
          this.$store.state.itens = response.data
          this.$store.commit('itens', response.data)
        })
    }
  },
  {
    name: 'APP_ITENS_SUCATA_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/item')
        .then(response => {
          this.$store.state.itensSucata = response.data
          this.$store.commit('itensSucata', response.data)
        })
    }
  },
  {
    name: 'APP_COMPRADORES_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/comprador')
        .then(response => {
          this.$store.state.compradores = response.data
          this.$store.commit('compradores', response.data)
        })
    }
  },
  {
    name: 'APP_FORNECEDORES_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'comercial/fornecedorList')
        .then(response => {
          this.$store.commit('fornecedores', response.data)
        })
    }
  },
  {
    name: 'APP_FUNCIONARIOS_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'rh/funcionario')
        .then(response => {
          this.$store.commit('funcionarios', response.data)
        })
    }
  },
  {
    name: 'APP_PRECO_MEDIO_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/PrecoMedioAtual')
        .then(response => {
          this.$store.state.precoMedioAtual = parseFloat(response.data.precoMedioAtual)
          // this.$store.dispatch('precoMedioAtual',parseFloat(response.data.precoMedioAtual))
        })
    }
  },
  {
    name: 'APP_ESTOQUE_FISICO_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/EstoqueAtual/F')
        .then(response => {
          this.$store.state.estoqueFisico = parseFloat(response.data.qtAtual)
          // this.$store.dispatch('estoqueFisico',parseFloat(response.data.qtAtual))
        })
    }
  },
  {
    name: 'APP_ESTOQUE_CONTABIL_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/EstoqueAtual/E')
        .then(response => {
          this.$store.state.estoqueContabil = parseFloat(response.data.qtAtual)
          // this.$store.dispatch('estoqueContabil',parseFloat(response.data.qtAtual))
        })
    }
  },
  {
    name: 'APP_SAIDAS_CLIENTE_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/SaidasCliente/15')
        .then(response => {
          this.$store.state.saidasCliente = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_SETORES_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/rh/setor/')
        .then(response => {
          this.$store.state.setores = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_PRECO_PADRAO_FORNECEDOR_GET',
    // @error api response data
    callback: function () {
      // console.log(this.$store.state.fornecedor)
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/precoPadraoEntradaFornecedor/' + this.$store.state.fornecedor.CdFornec)
        .then(response => {
          this.$store.state.precoPadrao = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_METODO_PAGAMENTO_GET',
    // @error api response data
    callback: function () {
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http.get(this.$store.state.urlBase + 'v1/financeiro/formasPagamentoSelect/')
        .then(response => {
          this.$store.state.metodoPagamento = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_LOJA_GET',
    // @error api response data
    callback: function () {
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http.defaults.headers.common["Authorization"] = "Bearer " + this.$store.state.user.token;
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/loja/1')
        .then(response => {
          this.$store.state.loja = response.data
          window.loja = response.data
        })
    }
  },
  {
    name: 'APP_PRECO_PADRAO_CLIENTE_GET',
    // @error api response data
    callback: function () {
      // console.log(this.$store.state.fornecedor)
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/precoPadraoCliente/' + this.$store.state.cliente.CdCpfCgc)
        .then(response => {
          this.$store.state.precoPadraoSaida = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_TIPO_SUCATA_GET',
    // @error api response data
    callback: function () {
      // console.log(this.$store.state.fornecedor)
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/tiposucata/')
        .then(response => {
          this.$store.state.tiposucata = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_TIPO_SUCATA_SAIDA_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/tiposucatasaida/')
        .then(response => {
          this.$store.state.tiposucatasaida = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_CLINTES_BALANCAS_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/clientesbalanca/')
        .then(response => {
          this.$store.state.clientesbalanca = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_TIPO_FRETE_GET',
    // @error api response data
    callback: function () {
      console.log(this.$store.state.fornecedor)
      this.$http.get(this.$store.state.urlBase + 'v1/comercial/tipofrete/')
        .then(response => {
          this.$store.state.tiposfrete = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_IMPOSTONFE_GET',
    // @error api response data
    callback: function () {
      this.$http.get(this.$store.state.urlBase + 'v1/contabilidade/impostonfe/')
        .then(response => {
          this.$store.state.impostonfe = response.data
        })
    }
  },
  {
    name: 'APP_RESUMO_DIRETORIA',
    // @error api response data
    callback: function () {
      console.log(this.$store.state.resumoDia)
      this.$http.get(this.$store.state.urlBase + 'v1/diretoria/resumodiaApi')
        .then(response => {
          this.$store.state.resumoDia = response.data
          // window.getApp.$emit('APP_DASHBOARD_SUCCESS')
        })
    }
  },
  {
    name: 'APP_GET_DANFE',
    // @error api response data
    callback: function (NrNf) {
      if (NrNf) {
        this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
        this.$http
          .get(
            this.$store.state.urlBase + "v1/sefaz/Danfe64/" + NrNf
          )
          .then((response) => {
            this.$store.state.danfe.file = response.data;
            this.$store.state.danfe.exibirPDF = true
          });
      }
    }
  },
  {
    name: 'APP_GET_EVENTOS',
    // @error api response data
    callback: function (trans) {
      if (trans) {
        this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
        this.$http
          .get(
            this.$store.state.urlBase + "v1/financeiro/eventos/" + trans
          )
          .then((response) => {
            this.$store.state.eventos = response.data;
          });
      }
    }
  },
  {
    name: 'APP_GET_CCUSTO',
    // @error api response data
    callback: function (evento) {
      if (evento) {
        this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
        this.$http
          .get(
            this.$store.state.urlBase + "v1/financeiro/ccusto/" + evento
          )
          .then((response) => {
            this.$store.state.ccustos = response.data;
          });
      }
    }
  },
  // {
  //   name: 'APP_FORNECEDOR_SUCATA_GET',
  //   // @error api response data
  //   callback: function () {
  //     this.$http.get(this.$store.state.urlBase+'v1/comercial/fornecedorSucataComprador')
  //                       .then(response => {
  //                         this.$store.state.saidasCliente = response.data
  //                       })
  //   }
  // }

];
