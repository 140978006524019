<template>
    <v-dialog v-model="$store.state.dialogResumoDiario" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <!-- <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Fornecedor
        </v-btn> -->
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon flat dark @click="$store.state.dialogResumoDiario = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title class="headline">Resumo Diário</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-toolbar-items>
                    <v-btn dark flat @click="dialog = false">Save</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <!-- <v-card-title>
                <span class="headline">Resumo Diário</span>
            </v-card-title> -->
            <v-list three-line subheader>
                <v-subheader>Resumo do Dia - Entradas</v-subheader>
                    <v-layout wrap>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar >
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (Kg):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasDia.EntradaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (R$):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasDia.EntradaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Preço Médio:</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasDia.PrecoMedioDia).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                <v-subheader>Resumo do Dia - Entradas Sem Aço Cearense</v-subheader>
                    <v-layout wrap>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar >
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (Kg):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasDiaSemAco.EntradaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (R$):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasDiaSemAco.EntradaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Preço Médio:</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasDiaSemAco.PrecoMedioDia).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                <v-subheader>Acumulado do Mês - Entradas</v-subheader>
                    <v-layout wrap>
                            <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar >
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (Kg):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasMes.EntradaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (R$):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasMes.EntradaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Preço Médio:</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasMes.PrecoMedio).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                <v-subheader>Acumulado do Mês - Entradas Sem Aço Cearense</v-subheader>
                    <v-layout wrap>
                            <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar >
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (Kg):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasMesSemAco.EntradaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Entradas Totais (R$):</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasMesSemAco.EntradaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                        <v-flex lg4 sm12 xs12>
                            <v-list-tile avatar lg4 sm12 xs12>
                                <v-list-tile-content>
                                <v-list-tile-title>Preço Médio:</v-list-tile-title>
                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.entradasMesSemAco.PrecoMedio).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    
                    <!-- Saídas -->
                    <v-expansion-panel expand v-model="panel">
                        <v-expansion-panel-content class="green white--text headline">
                            <template v-slot:header >
                                <div>Painel das Saídas</div>
                            </template>
                            <v-card >
                                <!--<v-card-text class="">-->
                                    <v-subheader>Resumo do Dia - Saídas</v-subheader>
                                    <v-layout wrap>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar >
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (Kg):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasDia.SaidaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (R$):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasDia.SaidaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Quantidade:</v-list-tile-title>
                                                <v-list-tile-sub-title>{{$store.state.resumoDia.saidasDia.Quantidade}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-subheader>Resumo do Dia - Detalhamento: Saída Sinobrás</v-subheader>
                                    <v-layout wrap>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar >
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (Kg):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidaSinobras.SaidaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (R$):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidaSinobras.SaidaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Quantidade:</v-list-tile-title>
                                                <v-list-tile-sub-title>{{$store.state.resumoDia.saidaSinobras.Quantidade}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-subheader>Resumo do Dia - Detalhamento: Saída Durametal</v-subheader>
                                    <v-layout wrap>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar >
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (Kg):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidaDurametal.SaidaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (R$):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidaDurametal.SaidaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Quantidade:</v-list-tile-title>
                                                <v-list-tile-sub-title>{{$store.state.resumoDia.saidaDurametal.Quantidade}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-subheader>Acumulado do Mês - Saídas</v-subheader>
                                    <v-layout wrap>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar >
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (Kg):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasMes.SaidaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (R$):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasMes.SaidaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Quantidade:</v-list-tile-title>
                                                <v-list-tile-sub-title>{{$store.state.resumoDia.saidasMes.Quantidade}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-subheader>Acumulado do Mês - Detalhamento: Saída Sinobrás
</v-subheader>
                                    <v-layout wrap>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar >
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (Kg):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasMesSinobras.SaidaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (R$):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasMesSinobras.SaidaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Quantidade:</v-list-tile-title>
                                                <v-list-tile-sub-title>{{$store.state.resumoDia.saidasMesSinobras.Quantidade}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-subheader>Acumulado do Mês - Detalhamento: Saída Durametal</v-subheader>
                                    <v-layout wrap>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar >
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (Kg):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasMesDurametal.SaidaTotal).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}} kg</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Saídas Totais (R$):</v-list-tile-title>
                                                <v-list-tile-sub-title>{{parseFloat($store.state.resumoDia.saidasMesDurametal.SaidaTotalValor).toLocaleString('pt-BR',{ minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                        <v-flex lg4 sm12 xs12>
                                            <v-list-tile avatar lg4 sm12 xs12>
                                                <v-list-tile-content>
                                                <v-list-tile-title>Quantidade:</v-list-tile-title>
                                                <v-list-tile-sub-title>{{$store.state.resumoDia.saidasMesDurametal.Quantidade}}</v-list-tile-sub-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-flex>
                                    </v-layout>
                                <!--</v-card-text>-->
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    
            </v-list>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>

                    </v-layout>
                    
                </v-container>
                <!-- <small>* campo obrigatório</small> -->
            </v-card-text>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
                <!-- <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.getters.entrada.id">Salvar</v-btn> -->
            </v-card-actions>
        </v-card>
        
    </v-dialog>
</template>
<script>
import toastr from 'toastr'

export default {
    data () {
        return {
            modalDataEntrada:false,
            modalDataValidade:false,
            dialog: false,
            valor: 16,
            panel: [true]
        }
    },
    components: {
       
    },
    computed: {
        dtEntradaFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.entrada.dtEntrada) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.entrada.dtEntrada).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.entrada.dtEntrada = this.parseDate(date)
                return date
            }
        },
        dtValidadeFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.entrada.dtValidade) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.entrada.dtValidade).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.entrada.dtValidade = this.parseDate(date)
                return date
            }     
        }
    },
    methods : {
        salvar (){
            let entrada = this.$store.state.entrada
            // this.$http.post(this.$store.state.urlBase+'almoxarifado/entrada',entrada)
            //     .then(response => {
            //        if(typeof response.data.errors !== 'undefined'){
            //             //Erros de Validação
            //             for (var error in response.data.errors) {
            //                 toastr.error(response.data.errors[error],'Entrada de Itens')
            //             }
            //        }else{
            //            window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
            //            this.atualizar()
            //        }
                   
            // })
        },
        atualizar () {
            // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
            // this.$store.state.dialogResumoDiario = false
            this.$store.commit('fecharModalResumoDiario')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toFixed(2)
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt)
        }
  },
    mounted () {
        
    }
}
</script>
