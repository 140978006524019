<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <!-- mini statistic start -->
      <v-flex lg4 sm6 xs12>
        <mini-statistic
          icon="fa fa-bar-chart"
          :title="$store.state.estoqueFisico.toLocaleString('pt-BR')"
          sub-title="Estoque Físico (Kg)"
          color="green"
        ></mini-statistic>
      </v-flex>
      <!-- <v-flex lg4 sm6 xs12>
          <mini-statistic
            icon="fa fa-balance-scale"
            :title="$store.state.estoqueContabil.toLocaleString('pt-BR')"
            sub-title="Estoque Contábil (Kg)"
            color="light-blue"
          ></mini-statistic>
        </v-flex> -->
      <v-flex lg4 sm6 xs12>
        <mini-statistic
          icon="fa fa-usd"
          :title="$store.state.precoMedioAtual.toLocaleString('pt-BR')"
          sub-title="Preço Médio Entrada (R$)"
          color="amber"
        ></mini-statistic>
      </v-flex>
      <v-flex lg4 sm6 xs12>
        <mini-statistic
          icon="fa fa-file-pdf-o"
          title="Relatório"
          sub-title="Resumo do Dia"
          color="red"
          class="ponteiro"
          @click.native="relatorioDiretoria()"
          v-if="
            !getAcessoRotaNegado(
              $store.getters.user.setor.permissoes,
              '/diretoria/ResumoDiario'
            )
          "
        ></mini-statistic>
      </v-flex>
      <!-- mini statistic  end -->
      <v-flex lg6 sm12 xs12>
        <v-widget
          title="Quantidade de Carretas por dia/cliente (Últimos 15 dias)"
          content-bg="white"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getSaidasCliente"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <e-chart
              ref="chart"
              v-if="renderQtdChart"
              v-bind:path-option="options"
              height="400px"
              width="100%"
              v-bind:dataset="dataset"
            ></e-chart>
          </div>
        </v-widget>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <v-widget
          title="Peso Total de Saída por dia/cliente (Últimos 15 dias)"
          content-bg="white"
          id="peso"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getPesoSaidasCliente"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content" id="chartSaida">
            <e-chart
              id="eChartSaida"
              ref="chart"
              v-if="renderQtdChartPeso"
              v-bind:path-option="optionsPeso"
              height="400px"
              width="100%"
            ></e-chart>
          </div>
        </v-widget>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <v-widget
          title="Quantidade de Carretas por dia (Últimos 15 dias)"
          content-bg="white"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getSaidasClienteQuantidade"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <e-chart
              ref="chart"
              v-if="renderQtdChartQuantidade"
              v-bind:path-option="optionsQuantidade"
              height="400px"
              width="100%"
            ></e-chart>
          </div>
        </v-widget>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <v-widget
          title="Peso de Saída x Peso de Entrada (Últimos 15 dias)"
          content-bg="white"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getPesoSaidaEntrada"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <e-chart
              ref="chart"
              v-if="renderQtdChartPesoSaidaEntrada"
              v-bind:path-option="optionsPesoSaidaEntrada"
              height="400px"
              width="100%"
            ></e-chart>
          </div>
        </v-widget>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <v-widget title="Variação do Estoque (Últimos 30 dias)" content-bg="white">
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getEstoque">refresh</v-icon>
          </v-btn>
          <div slot="widget-content">
            <e-chart
              ref="chart"
              v-if="renderEstoque"
              v-bind:path-option="optionsEstoque"
              height="400px"
              width="100%"
            ></e-chart>
          </div>
        </v-widget>
      </v-flex>
      <resumo-diario></resumo-diario>
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
import API from "@/api";
import EChart from "@/components/chart/echart";
import MiniStatistic from "@/components/widgets/statistic/MiniStatistic";
import PostListCard from "@/components/widgets/card/PostListCard";
import ProfileCard from "@/components/widgets/card/ProfileCard";
import PostSingleCard from "@/components/widgets/card/PostSingleCard";
import WeatherCard from "@/components/widgets/card/WeatherCard";
import PlainTable from "@/components/widgets/list/PlainTable";
import PlainTableOrder from "@/components/widgets/list/PlainTableOrder";
import VWidget from "@/components/VWidget";
import Material from "vuetify/es5/util/colors";
import VCircle from "@/components/circle/VCircle";
import BoxChart from "@/components/widgets/chart/BoxChart";
import ChatWindow from "@/components/chat/ChatWindow";
import CircleStatistic from "@/components/widgets/statistic/CircleStatistic";
import LinearStatistic from "@/components/widgets/statistic/LinearStatistic";
import Util from "@/util";
import ResumoDiario from "@/pages/comercial/ResumoDiario.vue";

var grafico;
export default {
  components: {
    API,
    VWidget,
    MiniStatistic,
    ChatWindow,
    VCircle,
    WeatherCard,
    PostSingleCard,
    PostListCard,
    ProfileCard,
    EChart,
    BoxChart,
    CircleStatistic,
    LinearStatistic,
    PlainTable,
    PlainTableOrder,
    ResumoDiario,
  },
  data: () => ({
    renderQtdChart: false,
    renderQtdChartPeso: false,
    renderQtdChartQuantidade: false,
    renderQtdChartPesoSaidaEntrada: false,
    renderEstoque: false,
    color: Material,
    selectedTab: "tab-1",
    linearTrending: [
      {
        subheading: "Sales",
        headline: "2,55",
        caption: "increase",
        percent: 15,
        icon: {
          label: "trending_up",
          color: "success",
        },
        linear: {
          value: 15,
          color: "success",
        },
      },
      {
        subheading: "Revenue",
        headline: "6,553",
        caption: "increase",
        percent: 10,
        icon: {
          label: "trending_down",
          color: "error",
        },
        linear: {
          value: 15,
          color: "error",
        },
      },
      {
        subheading: "Orders",
        headline: "5,00",
        caption: "increase",
        percent: 50,
        icon: {
          label: "arrow_upward",
          color: "info",
        },
        linear: {
          value: 50,
          color: "info",
        },
      },
    ],
    trending: [
      {
        subheading: "Email",
        headline: "15+",
        caption: "email opens",
        percent: 15,
        icon: {
          label: "email",
          color: "info",
        },
        linear: {
          value: 15,
          color: "info",
        },
      },
      {
        subheading: "Tasks",
        headline: "90%",
        caption: "tasks completed.",
        percent: 90,
        icon: {
          label: "list",
          color: "primary",
        },
        linear: {
          value: 90,
          color: "success",
        },
      },
      {
        subheading: "Issues",
        headline: "100%",
        caption: "issues fixed.",
        percent: 100,
        icon: {
          label: "bug_report",
          color: "primary",
        },
        linear: {
          value: 100,
          color: "error",
        },
      },
    ],
  }),
  computed: {
    options: function () {
      return [
        ["dataset.source", this.siteTrafficData],
        ["color", [this.color.lightBlue.base, this.color.green.lighten1]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "bar"],
        ["series[0].areaStyle", {}],
        ["series[0].smooth", true],
        ["series[1].smooth", true],
        ["series[1].type", "bar"],
        ["series[1].areaStyle", {}],
      ];
    },
    optionsQuantidade: function () {
      return [
        ["dataset.source", this.siteTrafficDataQuantidade],
        ["color", [this.color.green.lighten1]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "line"],
        ["series[0].smooth", false],
      ];
    },
    optionsPeso: function () {
      return [
        ["dataset.source", this.siteTrafficDataPeso],
        ["color", [this.color.lightBlue.base, this.color.green.lighten1]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "bar"],
        ["series[0].areaStyle", {}],
        ["series[0].smooth", true],
        ["series[1].smooth", true],
        ["series[1].type", "bar"],
        ["series[1].areaStyle", {}],
      ];
    },
    optionsPesoSaidaEntrada: function () {
      return [
        ["dataset.source", this.siteTrafficDataPesoSaidaEntrada],
        ["color", [this.color.lightBlue.base, this.color.amber.base]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "bar"],
        ["series[0].areaStyle", {}],
        ["series[0].smooth", true],
        ["series[1].smooth", true],
        ["series[1].type", "bar"],
        ["series[1].areaStyle", {}],
      ];
    },
    optionsEstoque: function () {
      return [
        ["dataset.source", this.siteTrafficDataEstoque],
        ["color", [this.color.lightBlue.base, this.color.red.base]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "line"],
        // ['series[0].areaStyle', {}],
        ["series[0].smooth", true],
        // ['series[1].smooth', true],
        // ['series[1].type', 'line'],
        // ['series[1].areaStyle', {}],
      ];
    },
    activity() {
      return API.getActivity();
    },
    posts() {
      return API.getPost(3);
    },
    siteTrafficData() {
      return this.$store.getters.saidasCliente;
    },
    siteTrafficDataQuantidade() {
      return this.$store.getters.saidasClienteQuantidade;
    },
    siteTrafficDataPeso() {
      // return API.monthVisitData
      return this.$store.state.pesoSaidasCliente;
    },
    siteTrafficDataPesoSaidaEntrada() {
      // return API.monthVisitData
      return this.$store.state.pesoSaidaEntrada;
    },
    siteTrafficDataEstoque() {
      // return API.monthVisitData
      return this.$store.state.estoque;
    },
    dataset: function () {
      var dataSet = {
        source: this.$store.getters.saidasCliente,
      };
      // console.log(dataSet)
      return dataSet;
    },
    locationData() {
      return API.getLocation;
    },
  },
  methods: {
    getAcessoRotaNegado(permissoes, path) {
      let acessoNegado = true;
      acessoNegado = API.getAcessoRotaNegado(permissoes, path, false);
      // console.log(acessoNegado)
      return acessoNegado;
    },
    relatorioDiretoria() {
      toastr.success("Gerando Resumo do Dia...", "Varejão da Sucata");
      window.getApp.$emit("APP_RESUMO_DIRETORIA");
      this.$store.commit("abrirModalResumoDiaro");
      //APP_RESUMO_DIRETORIA
      // console.log(parametros)
      /*this.$http.get(this.$store.state.urlBase+'v1/diretoria/resumodia',
            {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf'
                }
            }).then(response => {
                        if(typeof response.data.errors !== 'undefined'){
                            //Erros de Validação
                            for (var error in response.data.errors) {
                                toastr.error(response.data.errors[error],'Relatório Resumo do Dia')
                            }
                        }else{
                        // console.log(response)
                            var blob = new Blob([response.data], {
                                    type: 'application/pdf'
                            });
                            var url = window.URL.createObjectURL(blob)
                            window.open(url);
                            
                        }
                    
             })*/
    },
    getSaidasCliente() {
      toastr.success(
        "Atualizando Gráfico de Quantidade de Saídas por Cliente...",
        "Varejão da Sucata"
      );
      this.renderQtdChart = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/SaidasCliente/15")
        .then((response) => {
          this.$store.state.saidasCliente = response.data;
          this.renderQtdChart = true;
        });
    },
    getPesoSaidasCliente() {
      toastr.success(
        "Atualizando Gráfico de Peso de Saídas por Cliente...",
        "Varejão da Sucata"
      );
      this.renderQtdChartPeso = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/PesoSaidasCliente/15")
        .then((response) => {
          this.$store.state.pesoSaidasCliente = response.data;
          this.renderQtdChartPeso = true;
        });
    },
    getPesoSaidaEntrada() {
      toastr.success(
        "Atualizando Gráfico de Peso de Saídas x Entrada...",
        "Varejão da Sucata"
      );
      this.renderQtdChartPesoSaidaEntrada = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/PesoSaidaEntrada/15")
        .then((response) => {
          this.$store.state.pesoSaidaEntrada = response.data;
          this.renderQtdChartPesoSaidaEntrada = true;
        });
    },
    getEstoque() {
      toastr.success("Atualizando Variação do Estoque...", "Varejão da Sucata");
      this.renderEstoque = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/Estoque/30")
        .then((response) => {
          this.$store.state.estoque = response.data;
          this.renderEstoque = true;
        });
    },
    getSaidasClienteQuantidade() {
      toastr.success(
        "Atualizando Gráfico de Saídas Quantidade de Carretas...",
        "Varejão da Sucata"
      );
      this.renderQtdChartQuantidade = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/SaidasClienteQuantidade/15")
        .then((response) => {
          this.$store.state.saidasClienteQuantidade = response.data;
          this.renderQtdChartQuantidade = true;
        });
    },
    getList() {
      toastr.success("Atualizando dashboard...", "Varejão da Sucata");
      window.getApp.$emit("APP_PRECO_MEDIO_GET");
      window.getApp.$emit("APP_ESTOQUE_FISICO_GET");
      window.getApp.$emit("APP_ESTOQUE_CONTABIL_GET");
      this.getSaidasCliente();
      this.getPesoSaidasCliente();
      this.getSaidasClienteQuantidade();
      this.getPesoSaidaEntrada();
      this.getEstoque();
      toastr.clear();
    },
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logout: function () {
      window.localStorage.removeItem("user");
      this.$router.push("/login");
      this.$session.destroy();
      delete this.user;
    },
  },
  mounted() {
    // this.handleFullScreen();
    this.getList();
  },
  created() {
    //console.log(this.$refs.chart);
    //  var user = window.localStorage.getItem('user')
    //  console.log(user)
    delete window.getApp._events.APP_RECORD_UPDATED;
    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
    //Validando Login
    if (!this.$session.exists()) {
      this.logout();
    } else {
      this.user = this.$session.get("user");
      this.$store.state.user = this.user;
      //this.user = this.$store.state.user
      // console.log(this.user)
      this.$store.commit("setUser", this.user);
      this.$http.defaults.headers.common["Authorization"] = "Bearer " + this.user.token;
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
    }
  },
};
</script>
<style>
.ponteiro {
  cursor: pointer;
}
/* .v-chart canvas,.v-chart > div {
    /* max-width: 100% !important; */
/* height: 300px; */
/* } */
/* .echarts {
        height: 300px;
    } */
</style>
