export default [
  // {
  //   title: 'New user registered',
  //   color: 'light-green',
  //   icon: 'account_circle',
  //   timeLabel: 'Just now'
  // },
  // { divider: true, inset: true },
  // {
  //   title: 'New order received',
  //   color: 'light-blue',
  //   icon: 'shopping_cart',
  //   timeLabel: '2 min ago'
  // },
  // { divider: true, inset: true },
  // {
  //   title: 'New payment made',
  //   color: 'cyan',
  //   icon: 'payment',
  //   timeLabel: '24 min ago'
  // },
  // { divider: true, inset: true },
  // {
  //   title: 'New message from Michael',
  //   color: 'red',
  //   icon: 'email',
  //   timeLabel: '1 hour ago'
  // }
];
